
import { useEffect, useState } from 'react';

// img
import isotipo from './Images/IsotipoBlanco.png';
import profile from './Images/profile.jpg';
import solapa from './Images/solapa.png';
import circulo from './Images/circulo.png';
import multiplesCirculos from './Images/multiplesCirculos.png';
import estrella from './Images/estrella.png';
import isotipoN from './Images/isotipoN.png';


// components
import { TbClover } from "react-icons/tb";
import { GiMedicines } from "react-icons/gi";
import { RiNumbersLine } from "react-icons/ri";
import { LiaHandshake } from "react-icons/lia";
import { TfiWorld } from "react-icons/tfi";
import { PiMedalLight } from "react-icons/pi";
import { TbBeach } from "react-icons/tb";
import { BsHouseCheck } from "react-icons/bs";
import { IoSchoolOutline } from 'react-icons/io5';

// pop ups
import Swal from 'sweetalert2';


// styles
import "./Styles/AppLayout.scss"


//conexión a base de datos
import SteinStore from 'stein-js-client';



function App() {
  //Conexión a base de datos de asesores
  const AsesoresAPI = new SteinStore("https://api.steinhq.com/v1/storages/661deda64a642363121cafb2")

  const [asesoresDB, setAsesoresDB] = useState(null)
  const [gerentesDB, setGerentesDB] = useState(null)
  const [datosActuales, setDatosActuales] = useState({});
  const [indiceActual, setIndiceActual] = useState(0);
  const [intervaloTiempo, setIntervaloTiempo] = useState(5000); // Intervalo de 5 segundos


  // Funcionalidad del dashboard, activamos pestaña de carga
  const [loading, setLoading] = useState(true)
  const [initRender, setInitRender] = useState(1)
  const [animate, setAnimate] = useState(false);





  

  const fusionarBasesDatos = (asesoresDB, gerentesDB) => {
    const datosFusionados = [...asesoresDB, ...gerentesDB];
    return datosFusionados;
  };






  //focus view to the cardex
  useEffect(() => {
    if(loading) {
      Swal.fire({
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    } else {
      Swal.close()
      // Obtener el elemento div por su ID
      const miDiv = document?.getElementById('cardex');
    
      // Hacer que la página se desplace automáticamente hacia el div
      miDiv?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading])
  



  //make sure it doesnt renders twice
  // so it wont call the api twice
  useEffect(() => {
    if(initRender === 1) {
      setInitRender(2)
    }
  }, [initRender])



  //if it is the fitrst render, now get the api for DBs
  useEffect(() => {
    if(initRender === 2) {
      setInitRender(3)

      AsesoresAPI
        .read("Asesores-CRM-Valoret", {authentication: {
          username: "crm-valoret",
          password: "crm-valoret587943687953470985342879070953428687253487092538709523496780267"
        }})
        .then(data => {
          let asesoresDB = data;

          // Raw data
          setAsesoresDB(asesoresDB)







          //los gerentes están en otra petición
          AsesoresAPI
            .read("Gerentes-CRM-Valoret", {authentication: {
              username: "crm-valoret",
              password: "crm-valoret587943687953470985342879070953428687253487092538709523496780267"
            }})
            .then(data => {
              let gerentesDB = data;

              // Raw data
              setGerentesDB(gerentesDB)
              setLoading(false)
            })

            .catch(e => {
              console.error(e);
            });








        })

        .catch(e => {
          console.error(e);
        });
    }
    
  }, [initRender])








  useEffect(() => {
    // Verificar si se han cargado las bases de datos
    if (asesoresDB !== null && asesoresDB?.length > 0 && gerentesDB !== null && gerentesDB?.length > 0) {
      // Fusionar bases de datos
      const datosFusionados = fusionarBasesDatos(asesoresDB, gerentesDB);
      // console.log("FUSION", datosFusionados);
      // console.log("ACTUAL", datosFusionados[indiceActual], "INDICE", indiceActual);
      // Asignar el primer dato al cargar la página
      setDatosActuales(datosFusionados[indiceActual]);
    }
  }, [asesoresDB, gerentesDB, indiceActual]);









  useEffect(() => {
    const intervalo = setInterval(() => {
      if (asesoresDB && gerentesDB) {
        const datosFusionados = fusionarBasesDatos(asesoresDB, gerentesDB);
        const siguienteIndice = (indiceActual + 1) % datosFusionados.length;
        setIndiceActual(siguienteIndice);
        setDatosActuales(datosFusionados[siguienteIndice]);
      }
    }, intervaloTiempo);
  
    return () => clearInterval(intervalo);
  }, [asesoresDB, gerentesDB, indiceActual, intervaloTiempo]);
  






  // useEffect para manejar la animación cuando cambian los datos
  useEffect(() => {
    setAnimate(true); // Activar la animación cuando cambian los datos

    // Desactivar la animación después de un tiempo
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000); // Duración de la animación en milisegundos

    // Limpiar el timeout
    return () => clearTimeout(timeout);
  }, [datosActuales]); // Se ejecutará cada vez que cambie datosActuales





  useEffect(() => {
    const intervalo = setInterval(() => {
      if (asesoresDB && gerentesDB) {
        const datosFusionados = fusionarBasesDatos(asesoresDB, gerentesDB);
        const siguienteIndice = (indiceActual + 1) % datosFusionados.length;
        setIndiceActual(siguienteIndice);
        setDatosActuales(datosFusionados[siguienteIndice]);


        // Verificar si se ha alcanzado el último cardex
        if (siguienteIndice === 0) {
          // No hay variable opcional en la URL, navegar a la siguiente página
          window.location.href = 'https://dashboard.valoret.com.mx/';
        }
      }
    }, intervaloTiempo);
  
    return () => clearInterval(intervalo);
  }, [asesoresDB, gerentesDB, indiceActual, intervaloTiempo]);
  



  return (
    <div className="appLayout">

      <div className="bg-overlay"/>
      <header className="Header" onClick={()=> window.location.href = "https://www.valoret.com.mx"}><img src={isotipo}/></header>
      

      <main className="App">

        <div id='cardex' style={{height: "40px"}} />

        <section className="app-page-wrap-container">
          <div className={`app-page-container animated-div ${animate ? 'animate' : ''}`} >
            <div className='cardex-profile-wrap div1'>
              <img src={datosActuales?.imagen ?? profile} />
            </div>            

            <div className='cardex-info-wrapper div2'>
              
              <img src={solapa} className='cardex-info-solapa' />
              <img src={circulo} className='cardex-info-circulo c1' />
              <img src={circulo} className='cardex-info-circulo c2' />
              <img src={circulo} className='cardex-info-circulo c3' />
              <img src={circulo} className='cardex-info-circulo c4' />
              <img src={estrella} className='cardex-info-estrella' />
              <img src={multiplesCirculos} className='cardex-info-multiplesCirculos m1' />
              <img src={multiplesCirculos} className='cardex-info-multiplesCirculos m2' />
              <img src={multiplesCirculos} className='cardex-info-multiplesCirculos m3' />
              

              <div className='cardex-info'>
                <div className='asesor-wrapper'>
                  <img src={isotipoN} className='isotipo' />
                  <h2 className='h2'>{datosActuales?.cua ? `CUA ${datosActuales?.cua}` : `CUA -`}</h2>
                  <h1 className='h1'>{datosActuales?.nombre ? `${datosActuales.nombre.toUpperCase()}` : `-`}</h1>
                </div>

                <div style={{height: "3vh"}} />

                <div className='cardex-info-medallas'>
                  <div className={`c-medalla m-vida ${(!datosActuales?.medalla_vida || datosActuales?.medalla_vida == "0")  && "disabled"}`}>
                    <TbClover />
                    <span className='c-nombre'>Vida</span>
                  </div>
                  <div className={`c-medalla m-gmm ${(!datosActuales?.medalla_gmm || datosActuales?.medalla_gmm == "0") && "disabled"}`}>
                    <GiMedicines />
                    <span className='c-nombre'>GMM</span>
                  </div>
                  <div className={`c-medalla m-25pts ${(!datosActuales?.medalla_promedio25 || datosActuales?.medalla_promedio25 == "0") && "disabled"}`}>
                    <RiNumbersLine />
                    <span className='c-nombre'>25 Pts</span>
                  </div>
                  <div className={`c-medalla m-acompanador ${(!datosActuales?.medalla_acompanador || datosActuales?.medalla_acompanador == "0") && "disabled"}`}>
                    <LiaHandshake />
                    <span className='c-nombre'>Acompañador</span>
                  </div>
                  <div className={`c-medalla m-activo ${(!datosActuales?.medalla_activo || datosActuales?.medalla_activo == "0") && "disabled"}`}>
                    <TfiWorld />
                    <span className='c-nombre'>Activo</span>
                  </div>
                  <div className={`c-medalla m-congresista ${(!datosActuales?.medalla_congresista || datosActuales?.medalla_congresista == "0") && "disabled"}`}>
                    <PiMedalLight />
                    <span className='c-nombre'>Congresista</span>
                  </div>
                  <div className={`c-medalla m-cabos ${(!datosActuales?.medalla_cabos || datosActuales?.medalla_cabos == "0") && "disabled"}`}>
                    <TbBeach />
                    <span className='c-nombre'>Cabos</span>
                  </div>
                  <div className={`c-medalla m-noveles ${(!datosActuales?.medalla_noveles || datosActuales?.medalla_noveles == "0") && "disabled"}`}>
                    <IoSchoolOutline />
                    <span className='c-nombre'>Semario<br/>Noveles</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>


    </div>
  );
}

export default App;